<template>
  <form @submit.prevent="saveBreakdown" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Provider<span class="text-red-400">*</span></label
      >
      <input
        v-model="breakdown.provider"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Provider..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Policy Number<span class="text-red-400">*</span></label
      >
      <input
        v-model="breakdown.policy_number"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Policy Number..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Level of Cover</label>
      <select
        class="bge-input bge-select rounded"
        v-model="breakdown.policy_type"
      >
        <option value="Roadside, Home Start, Recovery">
          Roadside, Home Start, Recovery
        </option>
        <option
          value="Roadside, Home Start, Recovery, Onward Journey, European Cover"
        >
          Roadside, Home Start, Recovery, Onward Journey, European Cover
        </option>
        <option value="Roadside, Home Start, Recovery, European Cover">
          Roadside, Home Start, Recovery, European Cover
        </option>
      </select>
    </div>

    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Policy Holder</label>
      <input v-model="breakdown.policy_holder" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Policy Holder..." />
    </div> -->
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Emergency Contact</label>
      <input
        v-model="breakdown.emergency_contact"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Emergency Contact..."
      />
    </div>

    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Cover</label>
      <input v-model="breakdown.cover" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Cover..." />
    </div> -->
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Cover Start<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="breakdown.cover_start"
        format="dd/MM/yyyy"
        @selected="startDateSelected()"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Cover Renewal<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="breakdown.cover_renewal"
        :open-date="openEndDate"
        :disabledDates="disabledEndDates"
        format="dd/MM/yyyy"
        @closed="endDateClosed()"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Reminder Date<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="breakdown.reminder_date"
        format="dd/MM/yyyy"
        use-utc="false"
      ></datepicker>
    </div>

    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveBreakdown"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Breakdown</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
import futureDateByNMonth from "@/utils/futureDateByNMonth";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditBreakdown",
  components: {
    Datepicker,
    IsAuthorized,
  },
  data() {
    return {
      openEndDate: new Date(),
      disabledEndDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  props: {
    breakdown: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    if (this.breakdown.cover_start) {
      this.openEndDate = new Date(this.breakdown.cover_start);
      this.disabledEndDates.to = new Date(this.breakdown.cover_start);
    }
  },
  methods: {
    startDateSelected(e) {
      this.$nextTick(() => {
        this.openEndDate = new Date(this.breakdown.cover_start);
        this.disabledEndDates.to = new Date(this.breakdown.cover_start);
      });
    },
    endDateClosed(e) {
      var reminderDate = this.$moment(this.breakdown.cover_renewal)
        .subtract(31, "d")
        .toDate();
      if (
        this.$validator.isBefore(
          reminderDate instanceof Date
            ? reminderDate.toDateString()
            : reminderDate,
          this.breakdown.cover_start instanceof Date
            ? this.breakdown.cover_start.toDateString()
            : this.breakdown.cover_start,
        )
      ) {
        this.breakdown.reminder_date = this.$moment(
          this.breakdown.cover_renewal,
        )
          .subtract(1, "d")
          .toDate();
      } else {
        this.breakdown.reminder_date = reminderDate;
      }
    },
    saveBreakdown: function () {
      this.$emit("complete", this.breakdown);
    },
    deleteBreakdown: function () {
      this.$emit("delete", this.breakdown);
    },
  },
};
</script>
